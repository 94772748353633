var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _vm.school
              ? _c(
                  "b-link",
                  { staticClass: "brand-logo" },
                  [_c("vuexy-logo")],
                  1
                )
              : _vm._e(),
            _c(
              "b-form",
              {
                staticClass: "auth-login-form mt-3",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.validationForm.apply(null, arguments)
                  },
                },
              },
              [
                _c("b-card-title", { staticClass: "mb-1" }, [
                  _vm._v("Esqueceu a senha? 🔒 "),
                ]),
                _c("b-card-text", { staticClass: "mb-2" }, [
                  _vm._v(" Enviaremos uma senha provisória para o "),
                  _c("strong", [_vm._v("WhatsApp")]),
                  _vm._v(" cadastrado na sua conta. "),
                ]),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "E-mail",
                      "label-for": "forgot-password-email",
                    },
                  },
                  [
                    _c("b-form-input", {
                      class: { "is-invalid": _vm.$v.email.$error },
                      attrs: { id: "forgot-password-email" },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: _vm.loading,
                      variant: "primary",
                      type: "submit",
                      block: "",
                    },
                  },
                  [
                    _vm.loading
                      ? _c(
                          "div",
                          [
                            _c("b-spinner", {
                              attrs: { small: "", variant: "light" },
                            }),
                            _vm._v(" Verificando... "),
                          ],
                          1
                        )
                      : _c("div", [_vm._v("Enviar link")]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "text-center mt-2" },
              [
                _c(
                  "b-link",
                  { attrs: { to: { name: "auth-login" } } },
                  [
                    _c("feather-icon", { attrs: { icon: "ChevronLeftIcon" } }),
                    _vm._v(" Voltar "),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }