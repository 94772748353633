<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link v-if="school" class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-form class="auth-login-form mt-3" @submit.prevent="validationForm">
          <b-card-title class="mb-1">Esqueceu a senha? 🔒 </b-card-title>
          <b-card-text class="mb-2">
            Enviaremos uma senha provisória para o
            <strong>WhatsApp</strong> cadastrado na sua conta.
          </b-card-text>

          <b-form-group label="E-mail" label-for="forgot-password-email">
            <b-form-input
              id="forgot-password-email"
              v-model="email"
              :class="{ 'is-invalid': $v.email.$error }"
            />
          </b-form-group>

          <b-button :disabled="loading" variant="primary" type="submit" block>
            <div v-if="loading">
              <b-spinner small variant="light" /> Verificando...
            </div>
            <div v-else>Enviar link</div>
          </b-button>
        </b-form>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Voltar
          </b-link>
        </p>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  data() {
    return {
      email: "",
      loading: false,
      sideImg: require("@/assets/images/pages/login-digital.jpg"),
    };
  },
  methods: {
    validationForm() {
      this.$v.$touch();

      if (!this.$v.$error) {
        this.loading = true;

        this.$store
          .dispatch("AuthUser/forgetPassword", this.email)
          .then((res) => {
            if (res.cellphone) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Encontramos seu e-mail!",
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Aguarde! Enviaremos uma senha provisória para o número ${res.cellphone}. Cadastre uma nova senha assim que acessar!`,
                  },
                },
                {
                  position: "top-center",
                  timeout: 25000,
                  closeOnClick: false,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  showCloseButtonOnHover: true,
                }
              );
              this.$router.push({ name: 'auth-user-login' });
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ops...`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: "Não encontramos o seu e-mail. Verifique com o seu gestor.",
                  },
                },
                {
                  position: "top-center",
                  timeout: 25000,
                  closeOnClick: false,
                  pauseOnFocusLoss: false,
                  pauseOnHover: false,
                  showCloseButtonOnHover: true,
                }
              );
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  created() {
    console.log(this.sideImg);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
